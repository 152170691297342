import AllowEditingForRoles from 'Auth/AllowEditingForRoles';
import PresentForRoles from 'Auth/PresentForRoles';
import { Feature } from 'FeatureFlags/types';
import hasFeatureEnabled, { featureFlag } from 'FeatureFlags/utils/hasFeatureEnabled';
import { capitalize } from 'lodash';
import { CollapsableCard } from 'Order/OrderBuilder/shared/components/CollapsableCard';
import { StatusStepper } from 'Order/OrderBuilder/shared/components/StatusStepper';
import { mapCerillionQuoteStageHistoryToSteps } from 'Order/OrderBuilder/shared/components/StatusStepper/utils/mappers';
import { cloneQuote as cloneQuoteAction, resetQuoteStateAction } from 'Quotes/actions';
import { selectCanDeleteQuote, selectIsMultiQuote, selectNNILabel, selectSelectedPrice } from 'Quotes/selectors';
import { ProductType } from 'Quotes/types/productTypes';
import IQuoteRecordAttributesBase, { IMeta, QuoteStatus } from 'Quotes/types/quoteRecordAttributesBase';
import {
  ILocationGroup,
  IPriceData,
  IPricingProgress,
  IQuotesState,
  ProviderName,
  QuoteOrigin,
} from 'Quotes/types/store';
import React, { FunctionComponent, ReactNode, useEffect, useMemo, useState } from 'react';
import { connect, DispatchProp, useSelector } from 'react-redux';
import { Link, useMatch, useNavigate } from 'react-router-dom';
import { IAppState } from 'reducers';
import Alert from 'shared/components/atoms/Alert';
import Icon from 'shared/components/atoms/Icon';
import HeaderWithChildrenWrapper from 'shared/components/molecules/HeaderWithChildrenWrapper';
import Modal from 'shared/components/molecules/Modal';
import { ISummaryRow } from 'shared/components/molecules/QuoteAccordionHeader';
import Spinner from 'shared/components/molecules/SpinnerWithText';
import QuoteAccordion from 'shared/components/organisms/QuoteAccordion';
import IQuoteAccordionRenderProps from 'shared/components/organisms/QuoteAccordion/types/QuoteAccordionRenderProps';
import SwitchCustomerModal from 'shared/components/organisms/SwitchCustomerModal';
import {
  bearerForDisplay,
  multipleBandwidthForDisplay,
  multipleContractTermsForDisplay,
} from 'shared/utils/connectionCapacity';
import { usePreviousState } from 'shared/utils/customHooks';
import { formatDateTimeHuman } from 'shared/utils/dateHelper';
import getQueryParams from 'shared/utils/getQueryParams';
import { shortenId } from 'shared/utils/idFormatter';
import { userPermissions } from 'shared/utils/permissions';
import styled from 'styled-components';
import { setSelectedCompany } from 'User/actions';
import { bulkQuoteById, newQuote, quoteById, quoteByIdByLocation, quoteList } from '../Routes';
import { findProductTypeLabelForValue } from '../utils/findProductTypeLabelForValue';
import AccordionSection from './components/AccordionSection';
import AdditionalInfoTopPane, { AdditionalInfoStyles } from './components/AdditionalInfoTopPane';
import renderConfigure from './components/Configure/renderConfigure';
import { DeleteQuote } from './components/DeleteQuote';
import InternalQuotePanel from './components/InternalQuotePanel';
import renderLocation from './components/Location/renderLocation';
import Price from './components/Price/Price';
import renderProductTypes from './components/ProductType/renderProductTypes';
import { isConfigureDisabled, isLocationDetailCompleted } from './utils/accordionPanel';
import { locationSummary } from './utils/accordionSummary';
import { getIPTypeLabel } from './utils/ip';
import { findDiaIPAllocationOptionById } from './components/Configure/DIAConfig/types/diaIPAllocation';
import { QuoteMessages } from 'shared/components/organisms/Messages/Messages';
import { Navigation } from 'shared/RouterComponents';
import { BulkQuoteAlert } from './components/Price/Alerts';
import { isSupplierNNAT } from 'Quotes/utils/isPriceNNAT';

interface IQuoteBuilder {
  aEndLocation: ILocationGroup['aEnd'];
  bandwidth?: string;
  bearer?: string;
  bEndLocation: ILocationGroup['bEnd'];
  checkingAvailability: boolean;
  className?: string;
  cloneError?: boolean;
  clonePending?: boolean;
  cloneQuote(quote: IQuotesState['quote']): void;
  contractTerm: string;
  currentQuoteId?: string;
  meta: IMeta;
  productType?: ProductType | null;
  quote: IQuotesState['quote'];
  quoteState: IQuoteRecordAttributesBase['state'];
  resetQuoteState(): void;
  selectedCompanyId: string;
  editMode?: boolean;
  pricingProgress: IPricingProgress;
  prices: IPriceData[];
  lqPricingError?: string | null;
  shortBulkQuoteId?: string | null;
  fullBulkQuoteId?: string | null;
  requiresAvailabilityCheck: boolean;
  canDeleteQuote: ReturnType<typeof selectCanDeleteQuote>;
  selectedPrice: ReturnType<typeof selectSelectedPrice>;
  setSelectedCompanyId(id: string): void;
  quoteUpdating: boolean;
  isMultiQuote: ReturnType<typeof selectIsMultiQuote>;
}

const renderAccordion = (
  accordion: IQuoteAccordionRenderProps,
  helpers: IDisabledPanelHelpers,
  quote: IQuotesState['quote'],
  prices: IPriceData[]
) => {
  const onPanelChangeClick = helpers.onPanelChangeClick;
  return (
    <>
      <AccordionSection
        accordion={accordion}
        changeDisabled={true}
        className="accordion-section-product-selection"
        hasChangeButton={false}
        index={0}
        render={renderProductTypes}
        summaries={helpers.getSummaryForProductType()}
        title="Product"
      />
      <AccordionSection
        accordion={accordion}
        changeDisabled={helpers.isLocationDisabled() || helpers.isPricingInProgress() || helpers.isQuoteUpdating()}
        className="accordion-section-location"
        hasChangeButton={true}
        index={1}
        onChangeButtonClicked={onPanelChangeClick ? () => onPanelChangeClick(1) : undefined}
        render={renderLocation}
        summaries={helpers.getSummaryForLocation()}
        title="Location(s)"
      />
      <AccordionSection
        accordion={accordion}
        changeDisabled={helpers.isConfigureDisabled() || helpers.isPricingInProgress() || helpers.isQuoteUpdating()}
        className="accordion-section-connection-capacity"
        hasChangeButton={true}
        index={2}
        onChangeButtonClicked={onPanelChangeClick ? () => onPanelChangeClick(2) : undefined}
        render={renderConfigure}
        summaries={helpers.getSummaryForConnectionCapacity()}
        title={helpers.isAvailabilityCheckRequired() ? 'Access & Configuration' : 'Configuration'}
        quote={quote}
      />
      <AccordionSection
        accordion={accordion}
        changeDisabled={false}
        className="accordion-section-price"
        hasChangeButton={false}
        index={3}
        render={() => <Price onEdit={onPanelChangeClick ? () => onPanelChangeClick(2) : undefined} />}
        summaries={[{ topLine: `${prices.length} prices found`, bottomLine: null }]}
        title="Price(s)"
      />
    </>
  );
};

interface IDisabledPanelHelpers {
  isConfigureDisabled(): boolean;
  isLocationDisabled(): boolean;
  getSummaryForProductType(): ISummaryRow[];
  getSummaryForLocation(): ISummaryRow[] | null;
  getSummaryForConnectionCapacity(): ISummaryRow[] | null;
  onPanelChangeClick?(index: number): void;
  isPricingInProgress(): boolean;
  isQuoteUpdating(): boolean;
  isAvailabilityCheckRequired(): boolean;
}

export const APP_QUOTE_MODAL_PROMPT =
  'Any changes will result in the creation of a new quote and all previous data will remain unaffected for this quote.';
export const BULK_QUOTE_MODAL_PROMPT =
  'Any changes will create a new quote outside this bulk request. All previous quote data will remain unaffected.';
export const API_QUOTE_MODAL_PROMPT =
  'Any changes will create a new quote outside this API quote. All previous quote data will remain unaffected.';

export const getModalTextForQuoteOrigin = (origin: QuoteOrigin): string => {
  switch (origin) {
    case QuoteOrigin.APP:
      return APP_QUOTE_MODAL_PROMPT;
    case QuoteOrigin.BULK:
      return BULK_QUOTE_MODAL_PROMPT;
    default:
      return API_QUOTE_MODAL_PROMPT;
  }
};

export const QuoteBuilder: FunctionComponent<React.PropsWithChildren<IQuoteBuilder>> = (props) => {
  const navigate = useNavigate();
  return <QuoteBuilderInternal {...props} navigate={navigate} />;
};
export const QuoteBuilderInternal: FunctionComponent<React.PropsWithChildren<IQuoteBuilder & Navigation>> = ({
  aEndLocation,
  bEndLocation,
  meta,
  className,
  cloneError,
  clonePending,
  cloneQuote,
  contractTerm,
  currentQuoteId,
  checkingAvailability,
  productType,
  bearer,
  bandwidth,
  quote,
  quoteState,
  resetQuoteState,
  selectedCompanyId,
  editMode,
  pricingProgress,
  prices,
  lqPricingError,
  shortBulkQuoteId,
  fullBulkQuoteId,
  requiresAvailabilityCheck,
  canDeleteQuote,
  selectedPrice,
  quoteUpdating,
  isMultiQuote,
  navigate,
}) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [indexOfAccordionPanelToBeOpened, setIndexOfAccordionPanelToBeOpened] = useState<number | null>(null);
  const initialPanelIndex: string | null = getQueryParams()['initial-panel'];
  const isNewQuoteScreen = !!useMatch(newQuote);

  const isAvailabilityCheckRequired = (): boolean => requiresAvailabilityCheck;

  const isLocationDisabled = (): boolean => checkingAvailability || !productType;

  const nniLabel = useSelector(selectNNILabel);

  const isPricingInProgress = (): boolean => pricingProgress.fetchingPrice || false;

  const isQuoteUpdating = () => quoteUpdating;

  const getSummaryForProductType = (): ISummaryRow[] => {
    return [
      {
        topLine:
          findProductTypeLabelForValue(
            productType,
            !(meta.journey_origin === 'by_location' && productType === ProductType.P2P)
          ) || null,
        bottomLine: null,
      },
    ];
  };

  const getSummaryForLocation = () => () =>
    locationSummary(
      { location: aEndLocation, isNNAT: isSupplierNNAT(selectedPrice.a_end_access_type) },
      { location: bEndLocation, isNNAT: isSupplierNNAT(selectedPrice.b_end_access_type) },
      productType,
      meta,
      nniLabel,
      quote.origin
    );

  const getIsConnectionCapacityDisabled = () => () =>
    isConfigureDisabled({
      aEndLocation,
      bEndLocation,
      currentQuoteId,
      productType,
      meta,
    });

  const displayBandwidths = () => {
    const bandwidths = quote.chosen_bandwidths.length > 0 ? quote.chosen_bandwidths : [bandwidth!];

    return multipleBandwidthForDisplay(bandwidths);
  };

  const displayContractTerms = () => {
    const contractTerms =
      quote.chosen_term_lengths_in_months.length > 0 ? quote.chosen_term_lengths_in_months : [contractTerm!];

    return multipleContractTermsForDisplay(contractTerms);
  };

  const getTopLine = (): string | null => {
    if (productType !== ProductType.NNI2CCT) {
      if (bearer && bearer !== '' && ((bandwidth && bandwidth !== '') || quote.chosen_bandwidths.length > 0)) {
        return `Bearer: ${bearerForDisplay(
          bearer
        )} / Bandwidth(s): ${displayBandwidths()}, for ${displayContractTerms()}`;
      }
    } else {
      if (bandwidth || quote.chosen_bandwidths.length > 0) {
        return `Bandwidth(s): ${displayBandwidths()}, for ${displayContractTerms()}`;
      }
    }
    return null;
  };

  const getBottomLine = (): ReactNode => {
    const selectedIpId = quote.location.aEnd.ip.selectedId;
    const isManagedDIA = quote.location.aEnd.is_managed_dia;
    const providerName = quote.location.aEnd.cloudConnect.name;
    const diversified = quote.location.aEnd.cloudConnect.diversified;

    if (productType === ProductType.DIA) {
      return (
        <>
          <span>{findDiaIPAllocationOptionById(quote.location.aEnd.dia_ip_allocation!)?.label}</span>

          {selectedIpId && (
            <>
              <br />
              <span>
                {getIPTypeLabel(
                  selectedIpId,
                  quote.location.aEnd.is_managed_dia,
                  quote.location.aEnd.dia_ip_allocation
                )}
              </span>
            </>
          )}
          {isManagedDIA && (
            <>
              <br />
              <span>Managed DIA services</span>
            </>
          )}
        </>
      );
    } else if (
      (productType === ProductType.P2CCT || productType === ProductType.NNI2CCT) &&
      (quote.bandwidth !== '' || quote.chosen_bandwidths.length > 0)
    ) {
      if (providerName === ProviderName.AWS) {
        return `Cloud connect diversity ${diversified ? 'Yes' : 'No'}`;
      }
    }
    return null;
  };

  const getSummaryForConnectionCapacity = (): ISummaryRow[] | null => {
    return [
      {
        topLine: getTopLine(),
        bottomLine: getBottomLine(),
      },
    ];
  };

  const onPanelChangeClick =
    quoteState === QuoteStatus.PRICED || quoteState === QuoteStatus.ORDERED || quoteState === QuoteStatus.LAPSED
      ? (index: number) => {
          setShowModal(true);
          setIndexOfAccordionPanelToBeOpened(index);
        }
      : undefined;

  const enhanced = (helpers: IDisabledPanelHelpers) => {
    return (props: IQuoteAccordionRenderProps) => renderAccordion(props, helpers, quote, prices);
  };

  const initialActiveIndex = useMemo(() => {
    if (isNewQuoteScreen) {
      return 0;
    } else if (quoteState !== QuoteStatus.DRAFT) {
      return 3;
    } else if (initialPanelIndex === '1' || initialPanelIndex === '2') {
      return parseInt(initialPanelIndex, 10);
    } else if (
      isLocationDetailCompleted({
        aEndLocation,
        bEndLocation,
        currentQuoteId,
        productType,
        meta,
      })
    ) {
      return 2;
    } else {
      return 0;
    }
  }, []);

  const prevClonePending = usePreviousState(clonePending);

  useEffect(() => {
    if (prevClonePending && !clonePending && !cloneError && currentQuoteId) {
      navigate(
        meta.journey_origin === 'by_location'
          ? quoteByIdByLocation(currentQuoteId)
          : quoteById(currentQuoteId, indexOfAccordionPanelToBeOpened)
      );
    }
  }, [prevClonePending, clonePending, cloneError, currentQuoteId, navigate, indexOfAccordionPanelToBeOpened]);

  useEffect(() => {
    return () => {
      resetQuoteState();
    };
  }, [resetQuoteState]);

  return (
    <>
      <HeaderWithChildrenWrapper
        className={className}
        header={currentQuoteId ? `Quote ${capitalize(quote.shortId)}` : 'New quote'}
        subHeader={currentQuoteId ? `Saved on ${formatDateTimeHuman(quote.updatedAt)}` : ''}
        headerCopyToClipboardText={capitalize(quote.shortId)}
        extraHeaderContent={
          featureFlag.isEnabled(Feature.quoteCerillionStageUI) &&
          quote.cerillion_opportunity_stage_history && (
            <CollapsableCard
              header={<h4 className="title">Quote progress</h4>}
              subHeader={
                <div className="d-flex flex-column">
                  <StatusStepper
                    steps={mapCerillionQuoteStageHistoryToSteps(quote.cerillion_opportunity_stage_history!)}
                    currentStepId={quote.cerillion_stage!}
                    currentStepDescription={quote.cerillion_quote_status_message}
                    hideProgressBar={true}
                  />
                </div>
              }
            />
          )
        }
      >
        {fullBulkQuoteId && !isMultiQuote && (
          <PresentForRoles roles={userPermissions.viewEditBulkQuote}>
            <AdditionalInfoTopPane mainStyle={AdditionalInfoStyles.Info}>
              <Icon className="info_outline" name="info_outline" size="large" />
              This quote is part of bulk quote&nbsp;
              <Link id="bulk-quote-link" to={bulkQuoteById(fullBulkQuoteId)} target="_blank">
                {shortBulkQuoteId ? capitalize(shortBulkQuoteId) : fullBulkQuoteId}
              </Link>
              {quote.diverseOptionFor && (
                <span className="pl-1">
                  and is the diverse option for&nbsp;
                  <Link id="diverse-option-for-link" to={quoteById(quote.diverseOptionFor)} target="_blank">
                    Q-{shortenId(quote.diverseOptionFor)}
                  </Link>
                </span>
              )}
              {quote.diverseOption && (
                <span className="pl-1">
                  and has the diverse option&nbsp;
                  <Link id="diverse-option-link" to={quoteById(quote.diverseOption)} target="_blank">
                    Q-{shortenId(quote.diverseOption)}
                  </Link>
                </span>
              )}
            </AdditionalInfoTopPane>
          </PresentForRoles>
        )}

        {isMultiQuote && fullBulkQuoteId && <BulkQuoteAlert id={fullBulkQuoteId}></BulkQuoteAlert>}

        {lqPricingError && (
          <AdditionalInfoTopPane mainStyle={AdditionalInfoStyles.Error}>
            <>
              <Icon className="error_outline" name="error_outline" size="large" />
              Failed. {lqPricingError}
            </>
          </AdditionalInfoTopPane>
        )}
        <div className="quoteBuilder__wrapper">
          <InternalQuotePanel />
          <AllowEditingForRoles roles={userPermissions.createEditQuote}>
            <QuoteAccordion
              initialActiveIndex={initialActiveIndex}
              render={enhanced({
                getSummaryForConnectionCapacity,
                getSummaryForLocation: getSummaryForLocation(),
                getSummaryForProductType,
                isConfigureDisabled: getIsConnectionCapacityDisabled(),
                isLocationDisabled,
                onPanelChangeClick,
                isPricingInProgress,
                isQuoteUpdating,
                isAvailabilityCheckRequired,
              })}
            />
            {showModal && (
              <Modal
                closeBtnLabel="Cancel"
                disableActions={clonePending}
                title="New quote will be created"
                onClose={() => setShowModal(false)}
                onConfirm={() => cloneQuote(quote)}
              >
                {getModalTextForQuoteOrigin(quote.origin)}
                {clonePending && <Spinner className="saving" text="Creating new quote" size="large" />}
                {cloneError && (
                  <Alert>
                    Error creating new quote. Please try again later. If the problem persists, please contact your
                    Account Manager.
                  </Alert>
                )}
              </Modal>
            )}
            <SwitchCustomerModal selectedCompanyId={selectedCompanyId} path={quoteList} editMode={editMode} />
          </AllowEditingForRoles>
        </div>
      </HeaderWithChildrenWrapper>
      {canDeleteQuote && (
        <PresentForRoles roles={userPermissions.deleteQuote}>
          <div className="container">
            <DeleteQuote quoteId={currentQuoteId!} />
          </div>
        </PresentForRoles>
      )}

      {hasFeatureEnabled(Feature.messaging) && currentQuoteId && !quote.is_internal && <QuoteMessages />}
    </>
  );
};

const StyledQuoteBuilder = styled(QuoteBuilder)`
  height: 100%;

  div[class^='accordionSection_'] {
    border-bottom: 1px solid #d8d8d8;

    &:last-child {
      border-bottom: none;
    }
  }

  .saving {
    margin-top: 10px;
  }

  h4.title {
    color: ${(props) => props.theme.colours.primaryB1};
  }
`;

const mapStateToProps = (state: IAppState) => ({
  aEndLocation: state.quoteBuilder.quote.location.aEnd,
  bEndLocation: state.quoteBuilder.quote.location.bEnd,
  meta: state.quoteBuilder.quoteEndpointMeta,
  bandwidth: state.quoteBuilder.quote.bandwidth,
  bearer: state.quoteBuilder.quote.bearer,
  cloneError: state.quoteBuilder.cloning.error,
  clonePending: state.quoteBuilder.cloning.inProgress,
  checkingAvailability: state.quoteBuilder.checkingAvailability.inProgress,
  contractTerm: state.quoteBuilder.quote.contractTerm,
  currentQuoteId: state.quoteBuilder.currentQuoteId,
  productType: state.quoteBuilder.quote.productType,
  quote: state.quoteBuilder.quote,
  quoteState: state.quoteBuilder.state,
  selectedCompanyId: state.user.companyData.selectedCompanyId,
  editMode: state.quoteBuilder.editMode,
  pricingProgress: state.quoteBuilder.pricing.pricingProgress,
  prices: state.quoteBuilder.pricing.filteredPrices,
  lqPricingError: state.quoteBuilder.pricing.lqPricingError,
  shortBulkQuoteId: state.quoteBuilder.quote.shortBulkQuoteId,
  fullBulkQuoteId: state.quoteBuilder.quote.bulkQuoteId,
  requiresAvailabilityCheck: state.quoteBuilder.quote.requiresAvailabilityCheck,
  quoteUpdating: state.quoteBuilder.updating.inProgress,
  canDeleteQuote: selectCanDeleteQuote(state),
  selectedPrice: selectSelectedPrice(state),
  isMultiQuote: selectIsMultiQuote(state),
});

const mapDispatchToProps = (dispatch: DispatchProp['dispatch']) => ({
  cloneQuote: (quote: IQuotesState['quote']) => dispatch(cloneQuoteAction(quote)),
  resetQuoteState: () => dispatch(resetQuoteStateAction()),
  setSelectedCompanyId: (id: string) => dispatch(setSelectedCompany(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(StyledQuoteBuilder);
